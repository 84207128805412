import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { InformationType } from "./IconTextOverlay";

export function IconTextOverlayGenerator() {
    const [informationsID, setInformationsID] = useState<number[]>([]);
    const InformationIDRef = useRef(0);

    const removeInformation = (id: number) => {
        setInformationsID((oldInformationsID) =>
            oldInformationsID.filter((informationID) => informationID !== id)
        );
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const interval = document.getElementById(
            "interval"
        ) as HTMLInputElement;
        const informations: string[] = [];
        informationsID.forEach((informationID) => {
            const informationType = document.getElementById(
                "information-type-" + informationID
            ) as HTMLInputElement;
            const informationContent = document.getElementById(
                "information-content-" + informationID
            ) as HTMLInputElement;
            informations.push(
                informationType.innerText + ":" + informationContent.value
            );
        });
        const url = new URL(window.location.origin + "/overlay/icon-text/");
        url.searchParams.set("interval", interval.value);
        url.searchParams.set("informations", informations.join(","));

        setResultURL(url.toString());
    };

    const [resultURL, setResultURL] = useState<string>("");

    return (
        <div style={{ width: "100%", padding: "20px" }}>
            <Typography
                variant='h3'
                className='swordsouler__title'
                color='text.primary'>
                Générateur d'overlay
            </Typography>

            <form className='swordsouler__column' onSubmit={handleSubmit}>
                <TextField
                    id='interval'
                    label='Intervalle (en millisecondes)'
                    required
                    type='number'
                    inputProps={{ min: 4000 }}
                />

                {informationsID.map((informationID, index) => (
                    <InformationInput
                        id={informationID}
                        key={informationID}
                        remove={() => removeInformation(informationID)}
                    />
                ))}
                <Button
                    variant='outlined'
                    onClick={() =>
                        setInformationsID((oldInformationsID) => [
                            ...oldInformationsID,
                            ++InformationIDRef.current,
                        ])
                    }>
                    Ajouter du contenu
                </Button>
                <Button variant='contained' type='submit'>
                    Créer le lien
                </Button>
                <CopyToClipBoard url={resultURL} />
            </form>
        </div>
    );
}

function InformationInput(props: { id: number; remove: () => void }) {
    const { id, remove } = props;
    const [informationType, setInformationType] = useState<string>("");
    return (
        <div className='swordsouler__row'>
            <FormControl required fullWidth>
                <InputLabel id={"label-information-type-" + id}>
                    Type d'information
                </InputLabel>
                <Select
                    labelId={"label-information-type-" + id}
                    id={"information-type-" + id}
                    label="Type d'information"
                    value={informationType}
                    onChange={(e) => setInformationType(e.target.value)}>
                    {Object.keys(InformationType).map((informationType) => {
                        if (!isNaN(Number(informationType))) return null;
                        return (
                            <MenuItem
                                value={informationType.toString()}
                                key={informationType}>
                                {informationType}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <TextField
                id={"information-content-" + id}
                label='Contenu'
                required
                fullWidth
            />
            <Button
                variant='outlined'
                style={{ height: "56px" }}
                color='error'
                onClick={remove}>
                <DeleteIcon style={{ width: "30px", height: "30px" }} />
            </Button>
        </div>
    );
}

function CopyToClipBoard(props: { url: string }) {
    const { url } = props;

    return (
        <div
            className='swordsouler__row'
            style={{ flexWrap: "nowrap", justifyContent: "flex-end" }}>
            <TextField
                value={url}
                fullWidth
                inputProps={{ style: { paddingRight: "80px" } }}
            />
            <div
                className='swordsouler__row'
                style={{
                    position: "absolute",
                    width: 0,
                    height: "56px",
                    justifyContent: "flex-end",
                }}>
                <Button
                    variant='text'
                    onClick={() => navigator.clipboard.writeText(url)}
                    style={{
                        position: "relative",
                        marginRight: "15px",
                    }}>
                    Copier
                </Button>
            </div>
        </div>
    );
}
