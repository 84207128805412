import MediaCard, { MediaCardProps } from "../../components/MediaCard";
import { cardsSwordsoulerContact } from "./Contact";

export function SwordsoulerHome() {
    return (
        <div className='row center-horizontal start-vertical'>
            {cards.map((card) => (
                <MediaCard key={card.title} {...card} />
            ))}
        </div>
    );
}

const cardsSwordsoulerGame: MediaCardProps[] = [
    {
        title: "Cocursor",
        description:
            "Cocursor est un prototype de jeu dans lequel vous devez collaborer en tant que curseur pour terminer des puzzles.",
        img: require("../../assets/icons/cocursor.png"),
        links: [
            {
                name: "Voir le projet",
                link: "/game/cocursor",
            },
        ],
    },
    {
        title: "Mooving Stellar VR",
        description:
            "Mooving Stellar VR est un jeu en réalité virtuelle se déroulant dans l'espace réalisé en 2022.",
        img: require("../../assets/icons/mooving_stellar_vr.png"),
        links: [
            {
                name: "Voir le projet",
                link: "/game/mooving-stellar-vr",
            },
        ],
    },
    {
        title: "Just Dodge",
        description:
            "Just Dodge est un jeu basé sur Undertale réalisé en 2021 en OCaml.",
        img: require("../../assets/icons/undertale.png"),
        links: [
            {
                name: "Voir le projet",
                link: "/game/just-dodge",
            },
        ],
    },
    {
        title: "Bomber Stars",
        description:
            "Bomber Stars est un jeu basé sur Bomberman (version Sega Saturn) réalisé en 2019.",
        img: require("../../assets/icons/bomber_stars.png"),
        links: [
            {
                name: "Voir le projet",
                link: "/game/bomber-stars",
            },
        ],
    },
    {
        title: "Bullet Project",
        description:
            "Bullet Project est un jeu basé sur Undertale réalisé en 2018 dans le cadre de la matière ISN (Informatique et Science du Numérique) en Terminale.",
        img: require("../../assets/icons/undertale.png"),
        links: [
            {
                name: "Voir le projet",
                link: "/game/bullet-project",
            },
        ],
    },
];

const cardsSwordsoulerProject: MediaCardProps[] = [
    {
        title: "GTLA",
        description:
            "GTLA est une application pour mettre des avis aux restaurants précedement visité.",
        img: require("../../assets/icons/gtla.png"),
        links: [
            {
                name: "Accéder au site",
                link: "https://www.gtla.reviews/",
            },
        ],
    },
    {
        title: "Genshin Codes",
        description:
            "Genshin Codes est une extension pour Chrome qui permet d'entrer les codes cadeaux pour le jeu Genshin Impact plus rapidement.",
        img: require("../../assets/icons/genshin_codes.png"),
        links: [
            {
                name: "Accéder à l'extension",
                link: "https://chrome.google.com/webstore/detail/genshin-codes/kmmallnelldeinhegegjdlbebglihcpa?hl=fr&authuser=0",
            },
        ],
    },
    {
        title: "Crocodyle",
        description:
            "Crocodyle est un streamer multigaming sur Twitch qui organise fréquement des guerre communautaire entre l'équipe Jaune et l'équipe Rouge. " +
            "Cette partie du site répertorie différents outils et mini-jeux servant au bon déroulement de cette guerre.",
        img: require("../../assets/icons/crocodyle.png"),
        links: [
            {
                name: "Accéder au site",
                link: "/crocodyle",
            },
        ],
    },
    {
        title: "Overlay Twitch",
        description: "",
        img: require("../../assets/icons/twitch.png"),
        links: [
            {
                name: "Accéder au site",
                link: "/overlay/icon-text/generator",
            },
        ],
    },
];

export const cards: MediaCardProps[] = [
    ...cardsSwordsoulerContact,
    ...cardsSwordsoulerGame,
    ...cardsSwordsoulerProject,
];
