import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Button,
} from "@mui/material";
import { Link } from "react-router-dom";

export type MediaCardProps = {
    img: string;
    title: string;
    description: string;
    links: { name: string; link: string }[];
    imagePadding?: number;
};

export default function MediaCard(props: MediaCardProps) {
    const { img, title, description, links, imagePadding } = props;
    return (
        <Card sx={{ maxWidth: 270, padding: "10px", margin: "10px" }}>
            <CardMedia
                component='img'
                alt='Card'
                src={img}
                className='no-select'
                style={{
                    padding: imagePadding ? imagePadding + "px" : "0px",
                    width: imagePadding
                        ? (270 - imagePadding * 2).toString() + "px"
                        : "270px",
                    aspectRatio: 1,
                }}
            />
            <CardContent style={{ paddingBottom: "0" }}>
                <Typography gutterBottom variant='h5' component='div'>
                    {title}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                    {description}
                </Typography>
            </CardContent>
            <CardActions>
                {links.map((link, id) => (
                    <Link
                        to={link.link}
                        className='no-decoration'
                        key={"link-" + id}>
                        <Button size='small'>{link.name}</Button>
                    </Link>
                ))}
            </CardActions>
        </Card>
    );
}
