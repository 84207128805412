import React from "react"


export default class Vote extends React.Component {

    state = {
        value: 0,
    }

    constructor(props) {
        super(props);

        this.refTimer = React.createRef();
    }

    getValue = () => {
        return this.state.value;
    }

    setValue = (value) => {
        this.setState({
            value: value
        })
    }

    reset = () => {
        this.setState({
            value: 0,
        })
    }

    render() {
        const {value} = this.state;
        const {column} = this.props;

        return (
            <div
                className="vote tile column"
                style={{zIndex: 0}}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {
                    value === "" ? null :
                    <span
                        className={"text"}
                        style={{
                            position: "relative",
                            height: 0,
                            top: "calc((-50px * 1.18)*7)",
                            zIndex: -10,
                        }}>{column + 1}</span>
                }
                <span className={"vote-text"}>{value}</span>
            </div>
        );
    }
};