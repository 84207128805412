import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
const crocoTHINK = require("../../../crocodyle/assets/images/crocoTHINK.png");
const tmi = require("tmi.js");

export function AnswerGuesser() {
    const theme = useTheme();
    const isDark = theme.palette.mode === "dark";
    const textColor = isDark ? "white" : "black";

    const [isStarted, setIsStarted] = useState<boolean>(false);
    const [result, setResult] = useState<{
        name: string;
        displayName: string;
        answer: string;
    }>();
    const question = useRef<string>("");
    const answer = useRef<string>("");

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const channelName = document.getElementById(
            "channel-name"
        ) as HTMLInputElement;
        const newQuestion = document.getElementById(
            "question"
        ) as HTMLInputElement;
        const newAnswer = document.getElementById("answer") as HTMLInputElement;

        question.current = newQuestion.value;
        answer.current = newAnswer.value;
        setIsStarted(true);
        connectTwitch(channelName.value);
    };

    const twitch = useRef<any>();

    const connectTwitch = (channelName: string) => {
        twitch.current = new tmi.client({
            channels: [channelName],
            options: { debug: true },
            connection: {
                reconnect: true,
                secure: true,
            },
            identity: {
                username: process.env.REACT_APP_TWITCH_BOT_USERNAME,
                password: process.env.REACT_APP_TWITCH_BOT_PASSWORD,
            },
        });
        twitch.current.connect();
        twitch.current.on(
            "message",
            (channel: any, tags: any, message: string, self: any) => {
                if (message.toLowerCase() === answer.current.toLowerCase()) {
                    twitch.current.say(
                        channel,
                        `@${tags.username} a trouvé la bonne réponse ! La réponse était "${answer.current}"`
                    );
                    setResult({
                        name: tags.username,
                        displayName: tags["display-name"],
                        answer: message,
                    });
                    twitch.current.disconnect();
                }
            }
        );
    };

    const copyUser = () => {
        navigator.clipboard.writeText(result ? "/user " + result?.name : "");
    };

    const reset = () => {
        setIsStarted(false);
        setResult(undefined);
    };

    return (
        <div
            style={{
                color: textColor,
                width: "100%",
                padding: "0 40px",
            }}>
            <Typography variant={"h4"} className='crocodyle__title'>
                <img src={crocoTHINK} alt='crocoTHINK' width={40} />
                Devinette
                <img
                    src={crocoTHINK}
                    alt='crocoTHINK'
                    width={40}
                    className='reverse-horizontally'
                />
            </Typography>
            <div
                className='crocodyle__answer'
                style={{
                    display: isStarted && !result ? "flex" : "none",
                }}>
                <Typography variant={"h5"}>{question.current}</Typography>
                <Typography
                    variant={"h6"}
                    className='crocodyle__italic'
                    style={{ opacity: 0.4 }}>
                    En attente de la bonne réponse
                </Typography>
                <CircularProgress />
            </div>
            <div
                className='crocodyle__answer'
                style={{
                    display: isStarted && result ? "flex" : "none",
                }}>
                <Typography variant={"h5"}>{question.current}</Typography>
                <Typography variant={"h6"} className='crocodyle__text'>
                    <strong style={{ color: theme.palette.primary.main }}>
                        {result?.displayName}
                    </strong>{" "}
                    a trouvé la bonne réponse !
                </Typography>
                <Typography variant={"h6"} className='crocodyle__text'>
                    La réponse était
                    <strong style={{ color: theme.palette.primary.main }}>
                        {result?.answer}
                    </strong>
                </Typography>
                <div className='crocodyle__row'>
                    <Button onClick={copyUser} variant='outlined' fullWidth>
                        Copier /user {result?.name}
                    </Button>
                    <Button onClick={reset} variant='contained' fullWidth>
                        Refaire une devinette
                    </Button>
                </div>
            </div>
            <form
                className='crocodyle__form'
                style={{
                    display: isStarted ? "none" : "flex",
                }}
                onSubmit={handleSubmit}>
                <TextField
                    id='channel-name'
                    className='crocodyle__input'
                    label='Nom de la chaine twitch'
                    variant='outlined'
                    required
                    defaultValue={"crocodyle_lol"}
                />
                <TextField
                    id='question'
                    className='crocodyle__input'
                    label='Question'
                    variant='outlined'
                    required
                />
                <InputPassword />
                <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    className='crocodyle__input'>
                    Lancer la devinette
                </Button>
            </form>
        </div>
    );
}

function InputPassword() {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    return (
        <FormControl
            variant='outlined'
            fullWidth
            className='crocodyle__input'
            required>
            <InputLabel htmlFor='answer'>Réponse</InputLabel>
            <OutlinedInput
                id='answer'
                type={showPassword ? "text" : "password"}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                label='Password'
                fullWidth
            />
        </FormControl>
    );
}
