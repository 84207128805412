import React from "react"

export default class Timer extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            time: 0,
            start: 0,
            isOn: false
        }
        this.startTimer = this.startTimer.bind(this)
        this.stopTimer = this.stopTimer.bind(this)
        this.resetTimer = this.resetTimer.bind(this)
    }

    startTimer() {
        this.setState({
            time: this.props.initialValue * 1000,
            start: Date.now() + this.props.initialValue * 1000,
            isOn: true
        })
        this.timer = setInterval(() => {
            let newTime = Math.abs(Math.round(((Date.now() - this.state.start)/1000)) * 1000);
            let newStart = this.state.start;
            if(newTime === 0) {

                if(this.props.performTurn()){
                    this.stopTimer();
                } else {
                    newTime = this.props.initialValue * 1000;
                    newStart = Date.now() + this.props.initialValue * 1000;
                }
            }
            this.setState({
                time: newTime,
                start: newStart
            });
        }, 1000);
    }

    stopTimer() {
        this.setState({isOn: false});
        clearInterval(this.timer);
    }

    resetTimer() {
        this.setState({time: 0})
    }

    render() {
        return(
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className={"text"} style={{fontSize: 40}}>{this.state.time === 0 ? "" : this.state.time/1000}</a>
        )
    }
}
