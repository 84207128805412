import { createTheme } from "@mui/material";
import { GamePresentation } from "../../../components/GamePresentation";

export function Cocursor() {
    return (
        <div style={{ backgroundColor: "#344e7b", flex: 1 }}>
            <GamePresentation
                title={"Cocursor"}
                trailer='https://www.youtube.com/embed/ojuX-cF2J2c'
                image={require("../../../assets/icons/cocursor.png")}
                textColor={"#FFFFFF"}
                buttonType='outlined'
                description={(color) => (
                    <>
                        <p className='paragraph'>
                            Cocursor est un prototype de jeu multijoueur, dans
                            lequel vous controlez un curseur. Vous devez
                            résoudre des énigmes en collaborant avec d'autres
                            joueurs pour terminer des niveaux.
                        </p>
                    </>
                )}
                download={
                    <iframe
                        src={process.env.PUBLIC_URL + "/game-player/cocursor"}
                        style={{
                            maxWidth: "1200px",
                            width: "100%",
                            aspectRatio: "1.673640167364017",
                            border: "1px solid #FFFFFF",
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                        className='no-select'
                        title='Just Dodge'
                    />
                }
            />
        </div>
    );
}

export const themeCocursor = createTheme({
    palette: {
        mode: "dark",
        primary: {
            contrastText: "#FFFFFF",
            main: "#344e7b",
        },
        secondary: {
            contrastText: "#FFFFFF",
            main: "#FFFFFF",
        },
    },
});
