import { Button, Typography, useTheme } from "@mui/material";
import "./GamePresentation.scss";

export type GamePresentationProps = {
    title: string;
    trailer?: string;
    image?: string;
    description: (color: string) => JSX.Element;
    download: string | JSX.Element;
    textColor?: string;
    buttonType?: "contained" | "outlined";
    table?: (color?: string) => JSX.Element;
};

export function GamePresentation(props: GamePresentationProps) {
    const theme = useTheme();
    const { title, trailer, image, description, download, table } = props;
    const isDark = theme.palette.mode === "dark";
    const textColor = props.textColor ?? (isDark ? "#FFFFFF" : "#000000");
    const buttonType = props.buttonType ?? (isDark ? "outlined" : "contained");
    const primaryColor = isDark ? undefined : theme.palette.primary.main;

    return (
        <div className='game-presentation'>
            <div className='game-presentation__title'>
                {image && <img src={image} alt='Game' />}
                <Typography variant='h3' color={textColor}>
                    {title}
                </Typography>
                {image && <img src={image} alt='Game' />}
            </div>
            <div className='game-presentation__content'>
                <div>
                    {trailer && (
                        <iframe src={trailer} title='Trailer' allowFullScreen />
                    )}
                    <span style={{ color: textColor }}>
                        {description(theme.palette.secondary.main)}
                    </span>
                </div>

                <div
                    style={{
                        overflow: "auto",
                        width: "100%",
                        display: table ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {table && table(primaryColor)}
                </div>
                {typeof download === "string" ? (
                    <Button
                        color='secondary'
                        variant={buttonType}
                        href={download}>
                        Télécharger
                    </Button>
                ) : (
                    download
                )}
            </div>
        </div>
    );
}
