/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import { keyframes } from "@emotion/react";
import { TokenType } from "../../../pages/crocodyle/connect-four/game/ConnectFourGame";
const p4Red = require("../../assets/images/p4_red.png");
const p4Yellow = require("../../assets/images/p4_yellow.png");

export default class TokenFalling extends React.Component {

    state = {
        type: TokenType.NONE,
        positionX: 0,
        positionY: 0,
        disabled: true,
    }

    componentDidMount() {

    }

    start = (type, positionX, positionY) => {
        this.setState({
            type: type,
            positionX: positionX,
            positionY: positionY,
            disabled: false,
        });
    }

    render() {
        const {type, positionX, positionY, disabled} = this.state;
        if(disabled) return null;

        const token = type === TokenType.YELLOW ? p4Yellow : (type === TokenType.RED ? p4Red : null);
        const tokenAnimationFalling = keyframes({
            "from": {
                "marginTop": 0
            },
            "to": {
                "marginTop": `calc((50px * 1.18) * ${(positionY+1)*2})`
            }
        });

        return (
            <div
                className="falling-token tile"
                style={{
                    marginLeft: "calc((50px * 1.18) * " + positionX + ")",
                }}>
                {
                    token === null ? null :
                        <img
                            src={token}
                            css={css({
                                position: "relative",
                                animation: `${(positionY+1)*0.1}s linear 0s ${tokenAnimationFalling} forwards`,
                                marginTop: 0,
                                zIndex: 0,
                                width: "50px",
                                height: "50px"
                            })}
                            alt="token"
                            onAnimationEnd={() => {
                                this.setState({
                                    disabled: true,
                                });
                                this.props.place(type, positionY, positionX);
                            }}/>
                }
            </div>
        );
    }
};