import { createTheme } from "@mui/material";
import { GamePresentation } from "../../../components/GamePresentation";

export function BulletProject() {
    return (
        <div style={{ backgroundColor: "#000000", flex: 1 }}>
            <GamePresentation
                title={"Bullet Project"}
                trailer='https://www.youtube.com/embed/s6sYSgVEwXc'
                image={require("../../../assets/icons/undertale.png")}
                textColor={"#FFFFFF"}
                buttonType='outlined'
                description={(color) => (
                    <>
                        <p className='paragraph'>
                            Bullet Project est un jeu basé sur Undertale réalisé
                            en 2018 dans le cadre de la matière ISN
                            (Informatique et Science du Numérique) en Terminale.
                        </p>
                        <p className='paragraph'>
                            Ce projet a obtenu la 2nde place dans la catégorie
                            "Jeu" lors d'un concours au sein de l'académie de
                            Versailles organisé par{" "}
                            <a
                                style={{ color: color }}
                                href='https://digicosme.cnrs.fr/concours-isn2018/'>
                                Digicosme
                            </a>
                            .
                        </p>
                        <p className='paragraph'>
                            Le but du jeu est de survivre le plus longtemps
                            possible en esquivant des obstacles venant de tous
                            les côtés.
                        </p>
                        <p className='paragraph'>
                            Pour se déplacer il suffit d'utiliser soit ZQSD,
                            soit les flêches directionnel.
                        </p>
                    </>
                )}
                download='/download/bullet_project.zip'
            />
        </div>
    );
}

export const themeUndertale = createTheme({
    palette: {
        mode: "dark",
        primary: {
            contrastText: "#FFFFFF",
            main: "#000000",
        },
        secondary: {
            contrastText: "#FFFFFF",
            main: "#b3b3ff",
        },
    },
    shape: {
        borderRadius: 8,
    },
});
