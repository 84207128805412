import { useEffect, useRef, useState } from "react";
import "./Chat.scss";
const tmi = require("tmi.js");

type MessageProps = {
    message: string;
    tags: any;
};

let badgesAssets: { [x: string]: { versions: { image_url_1x: any }[] } };
let myBadgesAssets: { [x: string]: { versions: { image_url_1x: any }[] } };
let betterTTVAssets: {
    id: string;
    code: string;
    imageType: string;
    animated: string;
    user: { displayName: string; id: string; name: string; providerId: string };
}[];

export function Chat() {
    const [messages, setMessages] = useState<any[]>([]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const channelName = urlParams.get("channel");
        if (!channelName) {
            console.error("No channel name provided");
            return;
        }
        connectTwitch(channelName);

        return () => {
            twitch.current.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const LoadAssets = async (url: string) => {
        const response = await fetch(url);
        const json = await response.text();
        return JSON.parse(json);
    };

    useEffect(() => {
        badgesAssets = {};
        LoadAssets("https://badges.twitch.tv/v1/badges/global/display").then(
            (data: any) => {
                badgesAssets = data.badge_sets;
            }
        );
    }, []);

    const twitch = useRef<any>();
    const connectTwitch = (channelName: string) => {
        twitch.current = new tmi.client({
            channels: [channelName],
            options: { debug: true },
            connection: {
                reconnect: true,
                secure: true,
            },
            identity: {
                username: process.env.REACT_APP_TWITCH_BOT_USERNAME,
                password: process.env.REACT_APP_TWITCH_BOT_PASSWORD,
            },
        });
        twitch.current.connect();
        twitch.current.on(
            "message",
            (channel: any, tags: any, message: string, self: any) => {
                console.log(tags);
                messageTreatment(channel, tags, message, self);
            }
        );
    };
    const messageTreatment = async (
        channel: any,
        tags: any,
        message: string,
        self: any
    ) => {
        if (myBadgesAssets === undefined) {
            myBadgesAssets = {};
            myBadgesAssets = (
                await LoadAssets(
                    `https://badges.twitch.tv/v1/badges/channels/${tags["room-id"]}/display`
                )
            ).badge_sets;
        }
        if (betterTTVAssets === undefined) {
            betterTTVAssets = [];
            betterTTVAssets = (
                await LoadAssets(
                    `https://api.betterttv.net/3/cached/users/twitch/${tags["room-id"]}`
                )
            ).sharedEmotes;
        }
        setMessages((messages) => [...messages, { message, tags }]);
    };

    return (
        <div className='overlay__chat__container no-select'>
            {messages.map((message, id) => (
                <Message {...message} key={"message - " + id} />
            ))}
        </div>
    );
}

function createEmoteSet(
    emotes: any,
    message: string
): {
    emoteId: string;
    emoteStart: number;
    emoteEnd: number;
    type: "twitch" | "bttv";
}[] {
    //find all bttv emotes in message and get their start and end index

    const emoteSet: {
        emoteId: string;
        emoteStart: number;
        emoteEnd: number;
        type: "twitch" | "bttv";
    }[] = [];

    for (let i = 0; i < betterTTVAssets.length; i++) {
        //it is possible that the same emote is used multiple times in a message
        const emote = betterTTVAssets[i];
        let emoteStart = message.indexOf(emote.code);
        while (emoteStart !== -1) {
            emoteSet.push({
                emoteId: emote.id + "/2x." + emote.imageType,
                emoteStart,
                emoteEnd: emoteStart + emote.code.length - 1,
                type: "bttv",
            });
            emoteStart = message.indexOf(emote.code, emoteStart + 1);
        }
    }

    if (!emotes) {
        return emoteSet;
    }
    const emoteKeys = Object.keys(emotes);
    for (let i = 0; i < emoteKeys.length; i++) {
        const emoteKey = emoteKeys[i];
        for (let j = 0; j < emotes[emoteKey].length; j++) {
            const emoteStart = parseInt(emotes[emoteKey][j].split("-")[0]);
            const emoteEnd = parseInt(emotes[emoteKey][j].split("-")[1]);
            emoteSet.push({
                emoteId: emoteKey,
                emoteStart,
                emoteEnd,
                type: "twitch",
            });
        }
    }
    emoteSet.sort((a, b) => a.emoteStart - b.emoteStart);
    return emoteSet;
}

function parseMessage(
    message: string,
    tags: any
): { type: "emote" | "text"; content: string }[] {
    const emotes = createEmoteSet(tags.emotes, message);
    if (emotes.length > 0) {
        const messageElements: { type: "emote" | "text"; content: string }[] =
            [];

        for (let i = 0; i < emotes.length; i++) {
            const emote = emotes[i];
            if (i === 0) {
                messageElements.push({
                    type: "text",
                    content: message.substring(0, emote.emoteStart),
                });
            } else {
                messageElements.push({
                    type: "text",

                    content: message.substring(
                        emotes[i - 1].emoteEnd + 1,
                        emote.emoteStart
                    ),
                });
            }
            messageElements.push({
                type: "emote",
                content: EmoteToUrl({
                    emoteId: emote.emoteId,
                    type: emote.type,
                }),
            });
            if (i === emotes.length - 1) {
                messageElements.push({
                    type: "text",
                    content: message.substring(emote.emoteEnd + 1),
                });
            }
        }
        return messageElements;
    } else {
        return [{ type: "text", content: message }];
    }
}

function Message(props: MessageProps) {
    const { tags } = props;
    const messageElements: { type: "emote" | "text"; content: string }[] =
        parseMessage(props.message, tags);

    const badges = [];
    const badgeKeys = tags.badges ? Object.keys(tags.badges) : [];
    for (let i = 0; i < badgeKeys.length; i++) {
        const badgeKey = badgeKeys[i];
        badges.push({
            badgeId: badgeKey,
            badgeVersion: tags.badges[badgeKey],
        });
    }

    return (
        <div className='overlay__chat__message'>
            <div style={{ height: 0 }}>
                <span className='overlay__chat__user'>
                    {badges.map((badge, id) => (
                        <Badge
                            badgeId={badge.badgeId}
                            key={"badge - " + id}
                            badgeVersion={badge.badgeVersion}
                        />
                    ))}
                    {tags["display-name"]}
                </span>
            </div>

            <div className='overlay__chat__message-elements'>
                {messageElements.map((messageElement, id) => {
                    if (messageElement.type === "text") {
                        return (
                            <span
                                key={"message element - " + id}
                                className='overlay__chat__text overlay__chat__message-element'>
                                {messageElement.content}
                            </span>
                        );
                    } else {
                        return (
                            <Emote
                                url={messageElement.content}
                                key={"message element - " + id}
                            />
                        );
                    }
                })}
            </div>
        </div>
    );
}

function EmoteToUrl(emote: { emoteId: string; type: "twitch" | "bttv" }) {
    if (emote.type === "bttv") {
        return "https://cdn.betterttv.net/emote/" + emote.emoteId;
    } else {
        return (
            "https://static-cdn.jtvnw.net/emoticons/v2/" +
            emote.emoteId +
            "/default/dark/2.0"
        );
    }
}

function Emote(props: { url: string }) {
    const { url } = props;
    return (
        <img
            src={url}
            className='overlay__chat__emote overlay__chat__message-element'
            alt='Emote'
        />
    );
}

function Badge(props: { badgeId: string; badgeVersion: number }) {
    const { badgeId, badgeVersion } = props;
    let imageUrl = "";
    if (myBadgesAssets && myBadgesAssets[badgeId]) {
        imageUrl =
            myBadgesAssets[badgeId]["versions"][badgeVersion]["image_url_1x"] ??
            "";
    } else if (badgesAssets && badgesAssets[badgeId]) {
        imageUrl =
            badgesAssets[badgeId]["versions"][badgeVersion]["image_url_1x"] ??
            "";
    }
    if (imageUrl === "") return null;
    return <img src={imageUrl} className='overlay__chat__badge' alt='Badge' />;
}
