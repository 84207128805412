import { Theme } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { HeaderProps } from "./components/Header";
import { GetHeader } from "./models/HeaderButton";
import { themeCrocodyle, themeSwordsouler, UserEnum } from "./models/User";
import { AnswerGuesser } from "./pages/crocodyle/answer-guesser/AnswerGuesser";
import { ConnectFourForm } from "./pages/crocodyle/connect-four/ConnectFourForm";
import { ConnectFourGame } from "./pages/crocodyle/connect-four/game/ConnectFourGame";
import { CrocodyleHome } from "./pages/crocodyle/Home";
import PlayerSelector from "./pages/crocodyle/player-selector/PlayerSelector";
import { Error404 } from "./pages/Error404";
import { Chat } from "./pages/overlay/chat/Chat";
import { IconTextOverlay } from "./pages/overlay/icon-text/IconTextOverlay";
import { IconTextOverlayGenerator } from "./pages/overlay/icon-text/IconTextOverlayGenerator";
import { SwordsoulerContact } from "./pages/swordsouler/Contact";
import {
    BomberStars,
    themeBomberStars,
} from "./pages/swordsouler/game/BomberStars";
import {
    BulletProject,
    themeUndertale,
} from "./pages/swordsouler/game/BulletProject";
import { Cocursor, themeCocursor } from "./pages/swordsouler/game/Cocursor";
import { JustDodge } from "./pages/swordsouler/game/JustDodge";
import {
    MoovingStellarVR,
    themeMoovingStellarVR,
} from "./pages/swordsouler/game/MoovingStellarVR";
import { SwordsoulerHome } from "./pages/swordsouler/Home";

export type AppRouteProps = {
    path: string;
    element: React.ReactNode;
    theme?: Theme;
    header?: HeaderProps;
};

export const appRoutes: AppRouteProps[] = [
    {
        path: "*",
        element: <Error404 />,
        theme: themeSwordsouler,
    },
    {
        path: "/",
        element: <SwordsoulerHome />,
        theme: themeSwordsouler,
        header: GetHeader(UserEnum.Swordsouler),
    },
    {
        path: "/game/bullet-project",
        element: <BulletProject />,
        theme: themeUndertale,
        header: GetHeader(UserEnum.Swordsouler),
    },
    {
        path: "/game/bomber-stars",
        element: <BomberStars />,
        theme: themeBomberStars,
        header: GetHeader(UserEnum.Swordsouler),
    },
    {
        path: "/game/just-dodge",
        element: <JustDodge />,
        theme: themeUndertale,
        header: GetHeader(UserEnum.Swordsouler),
    },
    {
        path: "/game/mooving-stellar-vr",
        element: <MoovingStellarVR />,
        theme: themeMoovingStellarVR,
        header: GetHeader(UserEnum.Swordsouler),
    },
    {
        path: "/game/cocursor",
        element: <Cocursor />,
        theme: themeCocursor,
        header: GetHeader(UserEnum.Swordsouler),
    },
    {
        path: "/contact",
        element: <SwordsoulerContact />,
        theme: themeSwordsouler,
        header: GetHeader(UserEnum.Swordsouler),
    },
    {
        path: "/crocodyle",
        element: <CrocodyleHome />,
        theme: themeCrocodyle,
        header: GetHeader(UserEnum.Crocodyle),
    },
    {
        path: "/crocodyle/connect-four",
        element: <ConnectFourForm />,
        theme: themeCrocodyle,
        header: GetHeader(UserEnum.Crocodyle),
    },
    {
        path: "/crocodyle/connect-four/game",
        element: <ConnectFourGame />,
        theme: themeCrocodyle,
        header: undefined,
    },
    {
        path: "/crocodyle/player-selector",
        element: <PlayerSelector />,
        theme: themeCrocodyle,
        header: GetHeader(UserEnum.Crocodyle),
    },
    {
        path: "/crocodyle/answer-guesser",
        element: <AnswerGuesser />,
        theme: themeCrocodyle,
        header: GetHeader(UserEnum.Crocodyle),
    },
    {
        path: "/overlay/icon-text",
        element: <IconTextOverlay />,
    },
    {
        path: "/overlay/icon-text/generator",
        element: <IconTextOverlayGenerator />,
        theme: themeSwordsouler,
        header: GetHeader(UserEnum.Swordsouler),
    },
    {
        path: "/overlay/chat",
        element: <Chat />,
    },
];

export function AppRoute() {
    return (
        <Routes>
            {appRoutes.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                />
            ))}
        </Routes>
    );
}
