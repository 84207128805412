import React from "react"
import Timer from "./Timer";
import { Button } from "@mui/material";
import { TokenType } from "../../../pages/crocodyle/connect-four/game/ConnectFourGame";
const p4Red = require("../../assets/images/p4_red.png");
const p4Yellow = require("../../assets/images/p4_yellow.png");

export default class Buttons extends React.Component {

    state = {
        type: TokenType.NONE,
        isStarted: false,
        isFinish: false
    }

    constructor(props) {
        super(props);

        this.refTimer = React.createRef();
    }

    setType = (type) => {
        this.setState({
            type: type
        })
    }

    start = () => {
        this.setState({
            isStarted: true
        })
    }

    finish = () => {
        this.setState({
            isFinish: true
        })
    }

    reset = () => {
        this.setState({
            type: TokenType.NONE,
            isStarted: false,
            isFinish: false
        })
    }

    render() {
        const { isStarted, isFinish } = this.state;
        const { startGame, resetGame, performTurn, turnTime } = this.props;
        const {type} = this.state;

        const token = type === TokenType.YELLOW ? p4Yellow : (type === TokenType.RED ? p4Red : null);

        return (
            <div className={"side column"}>
                <Timer ref={this.refTimer} initialValue={turnTime} performTurn={performTurn}/>
                {
                    isStarted ?
                        (isFinish ? 
                            <Button 
                                variant="outlined" 
                                onClick={() => {
                                    resetGame();
                                    this.refTimer.current.stopTimer();
                                }}>
                                Recommencer
                            </Button> : null) :
                        <Button 
                            variant="outlined" 
                            onClick={() => {
                                startGame();
                                this.refTimer.current.startTimer();
                            }}>
                            Lancer la partie
                        </Button>
                }
                {
                    token === null ? null :
                        <>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a style={{marginTop: "10px"}} className={"text"}>Au tour des</a>
                            <img src={token} className="token-big" alt="token-big"/>
                        </>
                }
            </div>
        );
    }
};