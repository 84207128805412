import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useRef } from "react";
const crocoYELLOW = require("../../../crocodyle/assets/images/crocoYELLOW.png");
const crocoRED = require("../../../crocodyle/assets/images/crocoRED.png");
const tmi = require("tmi.js");

type Player = {
    name: string;
    displayName: string;
    subscriber: boolean;
    messages: string[];
};

enum Team {
    Red,
    Yellow,
}

export default function PlayerSelector() {
    const [enableTwitch, setEnableTwitch] = React.useState<boolean>(false);
    const [searchState, setSearchState] = React.useState<
        "all" | "sub" | "not-sub"
    >("all");
    const yellowPlayersRef = useRef<Player[]>([]);
    const [yellowPlayers, setYellowPlayers] = React.useState<Player[]>([]);
    const [currentYellowID, setCurrentYellowID] = React.useState<number>();
    const redPlayersRef = useRef<Player[]>([]);
    const [redPlayers, setRedPlayers] = React.useState<Player[]>([]);
    const [currentRedID, setCurrentRedID] = React.useState<number>();

    React.useEffect(() => {
        yellowPlayersRef.current = yellowPlayers;
    }, [yellowPlayers]);
    React.useEffect(() => {
        redPlayersRef.current = redPlayers;
    }, [redPlayers]);

    const theme = useTheme();
    const isDark = theme.palette.mode === "dark";
    const textColor = isDark ? "white" : "black";

    const resetPlayers = () => {
        setYellowPlayers([]);
        setRedPlayers([]);
        setCurrentYellowID(undefined);
        setCurrentRedID(undefined);
    };

    const selectRandomRedPlayer = () => {
        const filteredRedPlayers = redPlayers.filter(
            (player) =>
                searchState === "all" ||
                (searchState === "sub" && player.subscriber) ||
                (searchState === "not-sub" && !player.subscriber)
        );
        let indexRed: number = -1;
        if (filteredRedPlayers.length >= 0) {
            const randomIndex = Math.floor(
                Math.random() * filteredRedPlayers.length
            );
            indexRed = redPlayers.findIndex(
                (player) => player.name === filteredRedPlayers[randomIndex].name
            );
            setCurrentRedID(indexRed);
        }
        return indexRed;
    };

    const selectRandomYellowPlayer = () => {
        const filteredYellowPlayers = yellowPlayers.filter(
            (player) =>
                searchState === "all" ||
                (searchState === "sub" && player.subscriber) ||
                (searchState === "not-sub" && !player.subscriber)
        );
        let indexYellow: number = -1;
        if (filteredYellowPlayers.length > 0) {
            const randomIndex: number = Math.floor(
                Math.random() * filteredYellowPlayers.length
            );
            indexYellow = yellowPlayers.findIndex(
                (player) =>
                    player.name === filteredYellowPlayers[randomIndex].name
            );
            setCurrentYellowID(indexYellow);
        }
        return indexYellow;
    };

    const selectRandomPlayers = () => {
        const indexRed = selectRandomRedPlayer();
        const indexYellow = selectRandomYellowPlayer();

        if (indexRed !== -1 && indexYellow !== -1) {
            const channelName = document.getElementById(
                "channel-name"
            ) as HTMLInputElement;
            twitch.current.say(
                "#" + channelName.value,
                `@${yellowPlayers[indexYellow]?.name} VS @${redPlayers[indexRed]?.name}`
            );
        }
    };

    const twitch = useRef<any>();

    const connectTwitch = (channelName: string) => {
        twitch.current = new tmi.client({
            channels: [channelName],
            options: { debug: true },
            connection: {
                reconnect: true,
                secure: true,
            },
            identity: {
                username: process.env.REACT_APP_TWITCH_BOT_USERNAME,
                password: process.env.REACT_APP_TWITCH_BOT_PASSWORD,
            },
        });
        twitch.current.connect();
        twitch.current.on(
            "message",
            (channel: any, tags: any, message: string, self: any) => {
                const existingYellowPlayer = yellowPlayersRef.current.find(
                    (player) => {
                        return player.name === tags.username;
                    }
                );
                if (existingYellowPlayer) {
                    message = message
                        .replaceAll("crocoYELLOW", "")
                        .replaceAll("  ", " ");
                    console.log(message);
                    existingYellowPlayer.messages.push(message);
                    const tempYellowPlayers = [...yellowPlayersRef.current].map(
                        (player) =>
                            player.name !== existingYellowPlayer.name
                                ? player
                                : existingYellowPlayer
                    );
                    setYellowPlayers(tempYellowPlayers);
                    return;
                }

                const existingRedPlayer = redPlayersRef.current.find(
                    (player) => {
                        return player.name === tags.username;
                    }
                );
                if (existingRedPlayer) {
                    message = message
                        .replaceAll("crocoRED", "")
                        .replaceAll("  ", " ");
                    existingRedPlayer.messages.push(message);
                    const tempRedPlayers = [...redPlayersRef.current].map(
                        (player) =>
                            player.name !== existingRedPlayer.name
                                ? player
                                : existingRedPlayer
                    );
                    setRedPlayers(tempRedPlayers);
                    return;
                }

                if (new RegExp("crocoYELLOW").test(message)) {
                    message = message
                        .replace("crocoYELLOW", "")
                        .replaceAll("  ", " ");
                    const tempYellowPlayers = [...yellowPlayersRef.current];
                    tempYellowPlayers.push({
                        displayName: tags["display-name"],
                        name: tags.username,
                        subscriber: tags.subscriber,
                        messages: [message],
                    });
                    setYellowPlayers(tempYellowPlayers);
                    return;
                }
                if (new RegExp("crocoRED").test(message)) {
                    message = message
                        .replace("crocoRED", "")
                        .replaceAll("  ", " ");
                    const tempRedPlayers = [...redPlayersRef.current];
                    tempRedPlayers.push({
                        displayName: tags["display-name"],
                        name: tags.username,
                        subscriber: tags.subscriber,
                        messages: [message],
                    });
                    setRedPlayers(tempRedPlayers);
                    return;
                }
            }
        );
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const channelName = document.getElementById(
            "channel-name"
        ) as HTMLInputElement;

        if (enableTwitch) {
            twitch.current.disconnect();
        } else {
            connectTwitch(channelName.value);
        }
        setEnableTwitch(!enableTwitch);
    };

    return (
        <div
            style={{
                color: textColor,
                width: "100%",
            }}>
            <Typography variant={"h4"} className='crocodyle__title'>
                <img src={crocoYELLOW} alt='crocoYELLOW' width={40} />
                Sélectionneur de joueur
                <img src={crocoRED} alt='crocoRED' width={40} />
            </Typography>
            <div className='crocodyle__row'>
                <PlayerList players={yellowPlayers} team={Team.Yellow} />
                <form
                    onSubmit={handleSubmit}
                    className='crocodyle__form'
                    style={{ flex: 2, alignSelf: "flex-start" }}>
                    <TextField
                        id='channel-name'
                        className='crocodyle__input'
                        label='Nom de la chaine twitch'
                        variant='outlined'
                        required
                        defaultValue={"crocodyle_lol"}
                        disabled={enableTwitch}
                    />
                    <FormControl fullWidth className='crocodyle__input'>
                        <InputLabel id='demo-simple-select-label'>
                            Type de recherche
                        </InputLabel>
                        <Select
                            id='search-type'
                            label='Type de recherche'
                            defaultValue={"all"}
                            onChange={(event) => {
                                const value = event.target.value;
                                if (
                                    value === "all" ||
                                    value === "sub" ||
                                    value === "not-sub"
                                ) {
                                    setSearchState(value);
                                }
                            }}>
                            <MenuItem value={"all"}>Tout le monde</MenuItem>
                            <MenuItem value={"sub"}>Sub</MenuItem>
                            <MenuItem value={"not-sub"}>Non-Sub</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant='contained' fullWidth type='submit'>
                        {enableTwitch
                            ? "Arrêter la recherche"
                            : "Lancer la recherche"}
                    </Button>
                    <div className='crocodyle__row'>
                        <Button
                            variant='outlined'
                            fullWidth
                            onClick={resetPlayers}
                            disabled={
                                yellowPlayers.length === 0 &&
                                redPlayers.length === 0
                            }>
                            Réinitialiser
                        </Button>
                        <Button
                            variant='outlined'
                            fullWidth
                            onClick={selectRandomPlayers}
                            disabled={
                                yellowPlayers.length === 0 &&
                                redPlayers.length === 0
                            }>
                            Tirer
                        </Button>
                    </div>
                    <div className='crocodyle__row'>
                        <PlayerSelected
                            onClick={selectRandomYellowPlayer}
                            key={
                                "current-yellow-player " +
                                (currentYellowID !== undefined
                                    ? yellowPlayers[currentYellowID].messages
                                          .length
                                    : "")
                            }
                            player={
                                currentYellowID !== undefined
                                    ? yellowPlayers[currentYellowID]
                                    : undefined
                            }
                            team={Team.Yellow}
                        />
                        {/* show selected player */}
                        <Typography
                            className='crocodyle__label'
                            style={{ alignSelf: "flex", height: "50px" }}>
                            VS
                        </Typography>
                        <PlayerSelected
                            onClick={selectRandomRedPlayer}
                            key={
                                "current-red-player " +
                                (currentRedID !== undefined
                                    ? redPlayers[currentRedID].messages.length
                                    : "")
                            }
                            player={
                                currentRedID !== undefined
                                    ? redPlayers[currentRedID]
                                    : undefined
                            }
                            team={Team.Red}
                        />
                    </div>
                </form>
                <PlayerList players={redPlayers} team={Team.Red} />
            </div>
        </div>
    );
}

const PlayerSelected = React.memo(
    (props: { player?: Player; team: Team; onClick: () => void }) => {
        const { player, team, onClick } = props;
        return (
            <div className='crocodyle__player-selected'>
                <Typography className='crocodyle__label'>
                    {player && team === Team.Red && (
                        <img src={crocoRED} width={50} alt='crocoRED' />
                    )}
                    {player && player.displayName}
                    {player && team === Team.Yellow && (
                        <img src={crocoYELLOW} width={50} alt='crocoYELLOW' />
                    )}
                </Typography>
                {player && (
                    <Button variant='outlined' onClick={onClick}>
                        Changer ce joueur
                    </Button>
                )}
                <div className='crocodyle__list__reverse'>
                    {player &&
                        player.messages.length > 0 &&
                        player.messages.map((message, id) => (
                            <Typography key={id} className='crocodyle__text'>
                                {message}
                            </Typography>
                        ))}
                </div>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.player === nextProps.player;
    }
);

const PlayerList = React.memo(
    (props: { players: Player[]; team: Team }) => {
        const { players, team } = props;
        return (
            <div className='crocodyle__player-list'>
                {team === Team.Red && (
                    <img src={crocoRED} width={100} alt='crocoRED' />
                )}
                {team === Team.Yellow && (
                    <img src={crocoYELLOW} width={100} alt='crocoYELLOW' />
                )}
                <Typography className='crocodyle__label'>
                    {players.length}
                </Typography>
                {players.map((player, id) => (
                    <span
                        key={player.name + "-" + id}
                        className='crocodyle__label'
                        style={{
                            fontWeight: player.subscriber ? "bold" : "normal",
                        }}>
                        {player.displayName}
                    </span>
                ))}
            </div>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.players.length === nextProps.players.length;
    }
);
