import { createTheme } from "@mui/material";

export enum UserEnum {
    Swordsouler,
    Crocodyle,
}

export const themeSwordsouler = createTheme({
    palette: {
        primary: {
            contrastText: "#FFFFFF",
            dark: "#fba530",
            light: "#fee6c6",
            main: "#fcbb62",
        },
    },
    shape: {
        borderRadius: 8,
    },
});

export const themeCrocodyle = createTheme({
    palette: {
        mode: "dark",
        primary: {
            contrastText: "#FFFFFF",
            dark: "#608b33",
            light: "#add285",
            main: "#7ab041",
        },
    },
    shape: {
        borderRadius: 8,
    },
});
