import React from "react";
import "./ConnectFourGame.css";
import TokenHead from "../../../../crocodyle/components/ConnectFour/TokenHead";
import Tile from "../../../../crocodyle/components/ConnectFour/Tile";
import { checkWinner, WinType } from "./Algorithms";
import ScoreBoard from "../../../../crocodyle/components/ConnectFour/ScoreBoard";
import Buttons from "../../../../crocodyle/components/ConnectFour/Buttons";
import Vote from "../../../../crocodyle/components/ConnectFour/Vote";
import TokenFalling from "../../../../crocodyle/components/ConnectFour/TokenFalling";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
const tmi = require("tmi.js");

const getUrlParams = (search: string) => {
    let hashes = search.slice(search.indexOf("?") + 1).split("&");
    return hashes.reduce((params, hash) => {
        let [key, val] = hash.split("=");
        return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
};

export enum TokenType {
    NONE = 0,
    YELLOW = 1,
    RED = 2,
}

export function ConnectFourGame() {
    const theme = useTheme();
    const isDark = theme.palette.mode === "dark";
    const textColor = isDark ? "white" : "black";

    const width = 7;
    const height = 6;

    const tileRefs = React.useRef<
        {
            reset: Function;
            getType: Function;
            finish: Function;
            setWin: Function;
            setType: Function;
        }[][]
    >([]);
    const initGameState: any[] = [];
    [...Array(height)].forEach(() => {
        const row: number[] = [];
        [...Array(width)].forEach(() => {
            row.push(TokenType.NONE);
        });
        initGameState.push(row);
    });
    const avoidDoubleSocket = React.useRef(false);
    const gameState = React.useRef(initGameState);
    const refTokenFalling = React.useRef<
        { start: Function } | undefined | null
    >();
    const refTokenHead = React.useRef<
        | {
              setPosition: Function;
              reset: Function;
              generateRandomType: Function;
              startGame: Function;
              getType: Function;
              getPosition: Function;
              swapType: Function;
          }
        | undefined
        | null
    >();
    //const refScoreBoard = React.useRef<{setValues: Function, reset: Function, start: Function, finish: Function, setType: Function}>();
    const refScoreBoard = React.useRef<ScoreBoard | undefined | null>();
    const refButtons = React.useRef<
        | {
              reset: Function;
              start: Function;
              setType: Function;
              finish: Function;
          }
        | undefined
        | null
    >();
    const refVotes = React.useRef<
        { getValue: Function; setValue: Function; reset: Function }[]
    >([]);
    const votes = React.useRef<{ [key: number]: number }>({});
    const isStarted = React.useRef(false);
    const red = React.useRef<string[]>([]);
    const yellow = React.useRef<string[]>([]);
    const redVotes = React.useRef<string[]>([]);
    const yellowVotes = React.useRef<string[]>([]);
    const currentToken = React.useRef(TokenType.NONE);

    for (let i: number = 0; i < width; i++) {
        votes.current[i] = 0;
    }

    const location = useLocation();
    const params = React.useRef<{ [key: string]: string }>(
        getUrlParams(location.search)
    );

    React.useEffect(() => {
        if (avoidDoubleSocket.current) return;
        const twitch = new tmi.client({
            channels: [params.current["channel_name"]],
            options: { debug: true },
            connection: {
                reconnect: true,
                secure: true,
            },
            identity: {
                username: process.env.REACT_APP_TWITCH_BOT_USERNAME,
                password: process.env.REACT_APP_TWITCH_BOT_PASSWORD,
            },
        });
        twitch.connect(
            console.log(
                "Connected to Twitch has : " + params.current["channel_name"]
            )
        );

        twitch.on(
            "connectFailed",
            function (error: { toString: () => string }) {
                console.log("Connect Error: " + error.toString());
            }
        );

        twitch.on(
            "message",
            (
                channel: any,
                tags: { username: string },
                message: string,
                self: any
            ) => {
                console.log(tags);
                if (params.current["enable_team"] === "true") {
                    if (
                        ![...yellow.current, ...red.current].includes(
                            tags["username"]
                        )
                    ) {
                        if (new RegExp("crocoYELLOW").test(message)) {
                            console.log(tags.username + " has join yellow");
                            yellow.current.push(tags["username"]);
                        } else if (new RegExp("crocoRED").test(message)) {
                            console.log(tags.username + " has join red");
                            red.current.push(tags["username"]);
                        }
                        updateScoreBoard();
                    }
                }
                if (isStarted.current) {
                    if (currentToken.current === TokenType.YELLOW) {
                        if (
                            (params.current["enable_team"] === "true" &&
                                (yellow.current.length === 0 ||
                                    !yellow.current.includes(tags.username))) ||
                            (params.current["not_unique_message"] === "false" &&
                                yellowVotes.current.includes(tags.username))
                        )
                            return;
                    } else if (currentToken.current === TokenType.RED) {
                        if (
                            (params.current["enable_team"] === "true" &&
                                (red.current.length === 0 ||
                                    !red.current.includes(tags.username))) ||
                            (params.current["not_unique_message"] === "false" &&
                                redVotes.current.includes(tags.username))
                        )
                            return;
                    }
                    let result: any = [];
                    for (let i = 1; i <= width; i++) {
                        if (refVotes.current[i - 1].getValue() === "") continue;
                        if (new RegExp(i.toString()).test(message)) {
                            result.push(i - 1);
                        }
                    }
                    if (result.length === 0) return;
                    result = result[Math.floor(Math.random() * result.length)];
                    if (params.current["not_unique_message"] === "false") {
                        if (currentToken.current === TokenType.YELLOW) {
                            yellowVotes.current.push(tags.username);
                        } else if (currentToken.current === TokenType.RED) {
                            redVotes.current.push(tags.username);
                        }
                    }
                    votes.current[result] =
                        votes.current[result] === undefined
                            ? 1
                            : votes.current[result] + 1;
                    refVotes.current[result].setValue(votes.current[result]);
                    updateTokenHeadPosition();
                }
            }
        );
        avoidDoubleSocket.current = true;
        // eslint-disable-next-line
    }, []);

    const updateTokenHeadPosition = () => {
        if (Object.entries(votes.current).length === 0) return;
        const maxValue = Object.entries(votes.current).sort(
            (x, y) => y[1] - x[1]
        )[0];
        refTokenHead.current?.setPosition(maxValue[0]);
    };

    const updateScoreBoard = () => {
        refScoreBoard.current?.setValues(
            red.current.length,
            yellow.current.length
        );
    };

    const resetGame = () => {
        const initGameState: any[] = [];
        [...Array(height)].forEach(() => {
            const row: number[] = [];
            [...Array(width)].forEach(() => {
                row.push(TokenType.NONE);
            });
            initGameState.push(row);
        });
        gameState.current = initGameState;
        votes.current = {};
        yellowVotes.current = [];
        redVotes.current = [];
        refButtons.current?.reset();
        refScoreBoard.current?.reset();
        currentToken.current = TokenType.NONE;
        refButtons.current?.reset();
        refTokenHead.current?.reset();
        refTokenHead.current?.generateRandomType();
        for (const id in refVotes.current) {
            refVotes.current[id].reset();
        }
        for (const rowID in tileRefs.current) {
            for (const columnID in tileRefs.current[rowID]) {
                tileRefs.current[rowID][columnID].reset();
            }
        }
    };

    const startGame = () => {
        isStarted.current = true;
        refButtons.current?.start();
        refScoreBoard.current?.start();
        currentToken.current = refTokenHead.current?.generateRandomType();
        refButtons.current?.setType(currentToken.current);
        refTokenHead.current?.startGame();
    };

    const performTurn = () => {
        //refTokenHead.current.setPosition(Math.floor(Math.random() * (width)));
        const token = refTokenHead.current?.getType();
        const columnID = refTokenHead.current?.getPosition();
        currentToken.current = refTokenHead.current?.swapType();
        refButtons.current?.setType(currentToken.current);
        votes.current = {};
        for (let i = 0; i < width; i++) {
            if (tileRefs.current[0][i].getType() === TokenType.NONE) {
                votes.current[i] = 0;
                refVotes.current[i].setValue(0);
            }
        }
        if (Object.keys(votes.current).length === 0) {
            for (const rowID in tileRefs.current) {
                for (const columnID in tileRefs.current[rowID]) {
                    tileRefs.current[rowID][columnID].finish();
                }
            }
            refButtons.current?.finish();
            refScoreBoard.current?.finish();
            refScoreBoard.current?.setType(TokenType.NONE);
            refTokenHead.current?.reset();
            isStarted.current = false;
            return 3;
        }

        yellowVotes.current = [];
        redVotes.current = [];
        const winner = dropAtColumn(token, columnID);
        if (winner !== TokenType.NONE) {
            for (const rowID in tileRefs.current) {
                for (const columnID in tileRefs.current[rowID]) {
                    tileRefs.current[rowID][columnID].finish();
                }
            }
            refButtons.current?.finish();
            refScoreBoard.current?.finish();
            refScoreBoard.current?.setType(winner);
            refTokenHead.current?.reset();
            isStarted.current = false;
        }
        return winner !== TokenType.NONE;
    };

    const dropAtColumn = (token: any, columnID: number) => {
        if (tileRefs.current[0].length === 0) return 0;
        //console.log(tileRefs)
        let rowID = height - 1;
        for (rowID; rowID >= 0; rowID--) {
            //console.log(rowID + " | " + columnID)
            if (tileRefs.current[rowID][columnID].getType() === TokenType.NONE)
                break;
        }
        if (rowID < 0) return 0;
        refTokenFalling.current?.start(token, columnID, rowID);
        gameState.current[rowID][columnID] = token;
        //console.log(winResult);
        const { winner, row, column, type } = checkWinner(gameState.current);
        if (type !== WinType.NONE)
            console.log(
                "type : " + type + " | row : " + row + " | column : " + column
            );

        for (let i = 0; i < 4; i++) {
            switch (type) {
                case WinType.VERTICAL:
                    tileRefs.current[row + i][column].setWin(true);
                    break;
                case WinType.HORIZONTAL:
                    tileRefs.current[row][column + i].setWin(true);
                    break;
                case WinType.DIAGONAL_RIGHT:
                    tileRefs.current[row + i][column + i].setWin(true);
                    break;
                case WinType.DIAGONAL_LEFT:
                    tileRefs.current[row - i][column + i].setWin(true);
                    break;
                default:
                    break;
            }
        }
        return winner;
    };

    if (gameState.current.length === 0) return null;
    return (
        <div
            className={"crocodyle__connect-four__container no-select"}
            style={{ color: textColor }}>
            <ScoreBoard
                ref={(ref) => (refScoreBoard.current = ref)}
                enableTeam={params.current["enable_team"] === "true"}
            />
            <div className={"crocodyle__connect-four__playable-area"}>
                <TokenHead ref={(ref) => (refTokenHead.current = ref)} />
                <TokenFalling
                    ref={(ref) => (refTokenFalling.current = ref)}
                    place={(token: any, rowID: number, columnID: number) => {
                        tileRefs.current[rowID][columnID].setType(token);
                        if (rowID === 0) {
                            delete votes.current[columnID];
                            refVotes.current[columnID].setValue("");
                            updateTokenHeadPosition();
                        }
                    }}
                />
                {gameState.current.map((row, rowID) => {
                    tileRefs.current.push([]);
                    return (
                        <div key={"row : " + rowID} className={"row"}>
                            {row.map((element: any, columnID: string) => (
                                <Tile
                                    key={"column : " + columnID}
                                    ref={(ref) => {
                                        if (ref !== null)
                                            tileRefs.current[rowID].push(ref);
                                    }}
                                    blind={
                                        rowID >= 2 &&
                                        params.current["blind"] === "true"
                                    }
                                    blindToken={
                                        params.current["blindToken"] === "true"
                                    }
                                />
                            ))}
                        </div>
                    );
                })}

                <div className={"row"}>
                    {[...Array(width)].map((element, id) => (
                        <Vote
                            key={"column : " + id}
                            ref={(ref) => {
                                if (ref !== null) refVotes.current.push(ref);
                            }}
                            column={id}
                        />
                    ))}
                </div>
            </div>
            <Buttons
                ref={(ref) => (refButtons.current = ref)}
                resetGame={resetGame}
                startGame={startGame}
                performTurn={performTurn}
                turnTime={parseInt(params.current["turn_time"])}
            />
        </div>
    );
}
