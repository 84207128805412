import React from "react"
import { TokenType } from "../../../pages/crocodyle/connect-four/game/ConnectFourGame";
const p4Red = require("../../assets/images/p4_red.png");
const p4Yellow = require("../../assets/images/p4_yellow.png");
const p4Mystery = require("../../assets/images/p4_mystery.png");
const p4Tile = require("../../assets/images/p4_tile.png");

export default class Tile extends React.Component {

    state = {
        type: TokenType.NONE,
        finish: false,
        win: false,
    }

    setWin = (win) => {
        this.setState({
            win: win
        })
    }

    setType = (type) => {
        this.setState({
            type: type
        })
    }

    getType = () => {
        return this.state.type;
    }

    reset = () => {
        this.setState({
            type: TokenType.NONE,
            finish: false,
            win: false,
        })
    }

    finish = () => {
        this.setState({
            finish: true,
        })
    }

    render() {
        const {type, win, finish} = this.state;
        const {blind, blindToken} = this.props;

        const token = type === TokenType.YELLOW ? p4Yellow : (type === TokenType.RED ? p4Red : null);

        return (
            <div style={{display: "flex"}}>
                {
                    (blind || (blindToken && token !== null)) && !finish ? <img src={p4Mystery} style={{zIndex: 20}} className={(win ? "token-win" : "") + " token in-tile"} alt="token"/> : (
                        token === null ? null :
                            <img src={token} className={(win ? "token-win" : "") + " token in-tile"} alt="token"/>
                    )
                }
                <img src={p4Tile} className="tile" alt="tile"/>
            </div>
        );
    }
};