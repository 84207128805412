import MediaCard, { MediaCardProps } from "../../components/MediaCard";
import "./Crocodyle.scss";

export function CrocodyleHome() {
    return (
        <div className='row center-horizontal start-vertical'>
            {cards.map((card) => (
                <MediaCard key={card.title} {...card} />
            ))}
        </div>
    );
}

// get random number between min and max
function getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const cardsCrocodyleMiniGame: MediaCardProps[] = [
    {
        title: "Puissance 4",
        description: "",
        img:
            getRandomInt(1, 2) === 1
                ? require("../../crocodyle/assets/images/p4_yellow.png")
                : require("../../crocodyle/assets/images/p4_red.png"),
        imagePadding: 30,
        links: [
            {
                name: "Accéder au puissance 4",
                link: "/crocodyle/connect-four",
            },
        ],
    },
];

const cardsCrocodyleTool: MediaCardProps[] = [
    {
        title: "Sélectionneur de joueur",
        description: "",
        img:
            getRandomInt(1, 2) === 1
                ? require("../../crocodyle/assets/images/crocoEQUIPEJAUNE.png")
                : require("../../crocodyle/assets/images/crocoEQUIPEROUGE.png"),
        imagePadding: 30,
        links: [
            {
                name: "Sélectionner un joueur",
                link: "/crocodyle/player-selector",
            },
        ],
    },
    {
        title: "Devinette",
        description: "",
        img: require("../../crocodyle/assets/images/crocoTHINK.png"),
        imagePadding: 30,
        links: [
            {
                name: "Faire une devinette",
                link: "/crocodyle/answer-guesser",
            },
        ],
    },
];

export const cards: MediaCardProps[] = [
    ...cardsCrocodyleMiniGame,
    ...cardsCrocodyleTool,
];
