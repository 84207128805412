import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageIcon from "@mui/icons-material/Language";
import InputIcon from "@mui/icons-material/Input";
import React, { useEffect, useRef, useState } from "react";
import "./IconTextOverlay.scss";

export enum InformationType {
    Youtube,
    Twitch,
    Twitter,
    Instagram,
    Facebook,
    Website,
    Command,
}

type Information = {
    type: InformationType;
    text: string;
};

const animationSpeed = 1000;

function loadInformations(stringInformations: string) {
    const informations: Information[] = [];
    if (stringInformations) {
        const informationsArray = stringInformations.split(",");
        informationsArray.forEach((information) => {
            const informationArray = information.split(":");
            const type = informationArray[0];
            const text = informationArray[1];
            informations.push({
                type: InformationType[type as keyof typeof InformationType],
                text: text,
            });
        });
    }
    return informations;
}

export function IconTextOverlay() {
    const [currentInformationID, setCurrentInformationID] = useState<number>(0);
    //get param
    const urlParams = new URLSearchParams(window.location.search);
    const interval = useRef<number>(
        parseInt(urlParams.get("interval") ?? "10000")
    );
    const informations = useRef<Information[]>(
        loadInformations(urlParams.get("informations") ?? "")
    );
    const upheaval = useRef<boolean>(urlParams.get("upheaval") === "true");

    React.useEffect(() => {
        const intervalID = setInterval(() => {
            setCurrentInformationID((currentInformationID) => {
                if (currentInformationID + 1 >= informations.current.length) {
                    return 0;
                } else {
                    return currentInformationID + 1;
                }
            });
        }, interval.current);

        return () => {
            clearInterval(intervalID);
        };
    }, []);

    return (
        <div className={"overlay-icon-text__background no-select"}>
            {informations.current.map((information, index) => (
                <IconTextDisplayer
                    key={index}
                    information={information}
                    disabled={index !== currentInformationID}
                    interval={interval.current}
                    upheaval={upheaval.current}
                />
            ))}
        </div>
    );
}

function IconTextDisplayer(props: {
    information: Information;
    disabled: boolean;
    interval: number;
    upheaval: boolean;
}) {
    const { information, disabled, interval, upheaval } = props;
    const { text, type } = information;
    const { icon, color } = getTypeInformation(type);
    const [performAppear, setPerformAppear] = useState<boolean>(false);
    const [performDisappear, setPerformDisappear] = useState<boolean>(false);

    useEffect(() => {
        if (!disabled) {
            setPerformAppear(true);
            setTimeout(() => {
                setPerformAppear(false);
            }, animationSpeed);
            setTimeout(() => {
                setPerformDisappear(true);
                setTimeout(() => {
                    setPerformDisappear(false);
                }, animationSpeed);
            }, interval - animationSpeed);
        }
    }, [disabled, interval]);

    return (
        <div
            style={{ color: color }}
            className={
                "overlay-icon-text__container " +
                (disabled && !performAppear && !performDisappear
                    ? "disabled"
                    : performAppear
                    ? "animation-appear"
                    : performDisappear
                    ? "animation-disappear"
                    : "enabled")
            }>
            <div className='overlay-icon-text__element__container' id='icon'>
                <div className='overlay-icon-text__element__element'>
                    {icon}
                </div>
                <div className='overlay-icon-text__element__blur'>{icon}</div>
                <div
                    className={
                        "overlay-icon-text__element__container" +
                        (upheaval ? " upheaval" : "")
                    }
                    id='text'>
                    <span className='overlay-icon-text__element__element'>
                        {text}
                    </span>
                    {!upheaval && (
                        <span className='overlay-icon-text__element__blur'>
                            {text}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

function getTypeInformation(iconType: InformationType) {
    switch (iconType) {
        case InformationType.Youtube:
            return {
                icon: (
                    <YouTubeIcon style={{ width: "100px", height: "100px" }} />
                ),
                color: "#ff0000",
            };
        case InformationType.Twitch:
            return {
                icon: (
                    <img
                        alt='Twitch'
                        src={require("../../../assets/icons/twitch.png")}
                        style={{
                            width: "90px",
                            height: "90px",
                        }}
                    />
                ),
                color: "#a970ff",
            };
        case InformationType.Twitter:
            return {
                icon: (
                    <TwitterIcon style={{ width: "100px", height: "100px" }} />
                ),
                color: "#1d9bf0",
            };
        case InformationType.Instagram:
            return {
                icon: (
                    <InstagramIcon
                        style={{ width: "100px", height: "100px" }}
                    />
                ),
                color: "#d52d67",
            };
        case InformationType.Facebook:
            return {
                icon: (
                    <FacebookIcon style={{ width: "100px", height: "100px" }} />
                ),
                color: "#3c5a99",
            };
        case InformationType.Website:
            return {
                icon: (
                    <LanguageIcon style={{ width: "100px", height: "100px" }} />
                ),
                color: "#808080",
            };
        case InformationType.Command:
            return {
                icon: <InputIcon style={{ width: "100px", height: "100px" }} />,
                color: "#57a97e",
            };
    }
}
