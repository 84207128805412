import { UserEnum } from "./User";

export type HeaderButtonAction = string | (() => void) | HeaderButtonProps[];

export type HeaderButtonProps = {
    name: string;
    action?: HeaderButtonAction;
};

function GetHeaderButtons(type: UserEnum) {
    const buttons: HeaderButtonProps[] = [];
    switch (type) {
        case UserEnum.Swordsouler:
            buttons.push({
                name: "Accueil",
                action: "/",
            });
            buttons.push({
                name: "Mes jeux",
                action: [
                    {
                        name: "Bullet Project",
                        action: "/game/bullet-project",
                    },
                    {
                        name: "Bomber Stars",
                        action: "/game/bomber-stars",
                    },
                    {
                        name: "Just Dodge",
                        action: "/game/just-dodge",
                    },
                    {
                        name: "Mooving Stellar VR",
                        action: "/game/mooving-stellar-vr",
                    },
                    {
                        name: "Cocursor",
                        action: "/game/cocursor",
                    },
                ],
            });
            buttons.push({
                name: "Mes Projets",
                action: [
                    {
                        name: "Crocodyle",
                        action: "/crocodyle",
                    },
                    {
                        name: "GTLA",
                        action: "https://www.gtla.reviews/",
                    },
                    {
                        name: "Genshin Codes",
                        action: "https://chrome.google.com/webstore/detail/genshin-codes/kmmallnelldeinhegegjdlbebglihcpa?hl=fr&authuser=0",
                    },
                    {
                        name: "Overlay Twitch",
                        action: "/overlay/icon-text/generator",
                    },
                ],
            });
            buttons.push({
                name: "Contact",
                action: "/contact",
            });
            break;

        case UserEnum.Crocodyle:
            buttons.push({
                name: "Accueil",
                action: "/crocodyle",
            });
            buttons.push({
                name: "Mini-Jeux",
                action: [
                    {
                        name: "Puissance 4",
                        action: "/crocodyle/connect-four",
                    },
                ],
            });
            buttons.push({
                name: "Outils",
                action: [
                    {
                        name: "Sélectionneur de joueur",
                        action: "/crocodyle/player-selector",
                    },
                    {
                        name: "Devinette",
                        action: "/crocodyle/answer-guesser",
                    },
                ],
            });
            break;

        default:
            break;
    }
    return buttons;
}

export function GetHeader(type: UserEnum) {
    const title: HeaderButtonProps = {
        name: "",
    };
    switch (type) {
        case UserEnum.Swordsouler:
            title.name = "Swordsouler";
            title.action = "/";
            break;
        case UserEnum.Crocodyle:
            title.name = "Crocodyle";
            title.action = "/crocodyle";
            break;
    }
    return { title, buttons: GetHeaderButtons(type) };
}
