
export const WinType = Object.freeze({
    "NONE": 0,
    "VERTICAL": 1,
    "HORIZONTAL": 2,
    "DIAGONAL_RIGHT": 3,
    "DIAGONAL_LEFT": 4,
})

function checkLine(a,b,c,d) {
    // Check first cell non-zero and all cells match
    return ((a !== 0) && (a === b) && (a === c) && (a === d));
}

export function checkWinner(bd) {
    //return {winner: 0, row: -1, column: -1, type: WinType.NONE};
    // Check down
    for (let r = 0; r < 3; r++)
        for (let c = 0; c < 7; c++)
            if (checkLine(bd[r][c], bd[r+1][c], bd[r+2][c], bd[r+3][c]))
                return {winner: bd[r][c], row: r, column: c, type: WinType.VERTICAL};

    // Check right
    for (let r = 0; r < 6; r++)
        for (let c = 0; c < 4; c++)
            if (checkLine(bd[r][c], bd[r][c+1], bd[r][c+2], bd[r][c+3]))
                return {winner: bd[r][c], row: r, column: c, type: WinType.HORIZONTAL};

    // Check down-right
    for (let r = 0; r < 3; r++)
        for (let c = 0; c < 4; c++)
            if (checkLine(bd[r][c], bd[r+1][c+1], bd[r+2][c+2], bd[r+3][c+3]))
                return {winner: bd[r][c], row: r, column: c, type: WinType.DIAGONAL_RIGHT};

    // Check down-left
    for (let r = 3; r < 6; r++)
        for (let c = 0; c < 4; c++)
            if (checkLine(bd[r][c], bd[r-1][c+1], bd[r-2][c+2], bd[r-3][c+3]))
                return {winner: bd[r][c], row: r, column: c, type: WinType.DIAGONAL_LEFT};

    return {winner: 0, row: -1, column: -1, type: WinType.NONE};
}