import { createTheme } from "@mui/material";
import { GamePresentation } from "../../../components/GamePresentation";

export function MoovingStellarVR() {
    return (
        <div style={{ backgroundColor: "#000000", flex: 1 }}>
            <div className='game__background__container'>
                <video
                    autoPlay
                    muted
                    loop
                    id='video'
                    className='mooving-stellar-vr__background'>
                    <source
                        src='https://static.vecteezy.com/system/resources/previews/001/623/730/mp4/sci-fi-deep-space-background-loop-free-video.mp4'
                        type='video/mp4'
                    />
                    <source
                        src='https://static.vecteezy.com/system/resources/previews/001/623/730/sci-fi-deep-space-background-loop-free-video.webm'
                        type='video/webm'
                    />
                </video>
                <span className='mooving-stellar-vr__background__color' />
            </div>
            <div className='game__container'>
                <GamePresentation
                    title={"Mooving Stellar VR"}
                    trailer='https://www.youtube.com/embed/WqJ6skeQzYc'
                    image={require("../../../assets/icons/mooving_stellar_vr.png")}
                    textColor={"#FFFFFF"}
                    buttonType='outlined'
                    description={(color) => (
                        <>
                            <p className='paragraph'>
                                Mooving Stellar VR est un jeu en réalité
                                virtuelle se déroulant dans l'espace réalisé en
                                2022.
                            </p>
                            <p className='paragraph'>
                                Le but du jeu est de passer le maximum de vague
                                en détruisant les drones qui apparaissent. La
                                manette de gauche contrôle un sabre laser qui
                                renvoie les projectiles ennemis, et la manette
                                de droite contrôle un pistolet laser permettant
                                de détruire les drones ennemis. Il y a aussi la
                                possibilité de ralentir le temps temporairement.
                            </p>
                        </>
                    )}
                    download='/download/mooving_stellar_vr.zip'
                />
            </div>
        </div>
    );
}

export const themeMoovingStellarVR = createTheme({
    palette: {
        mode: "dark",
        primary: {
            contrastText: "#FFFFFF",
            main: "#002446",
        },
        secondary: {
            contrastText: "#FFFFFF",
            main: "#FFFFFF",
        },
    },
    shape: {
        borderRadius: 8,
    },
});
