import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
const MODS = require("../../../crocodyle/assets/images/MODS.gif");
const crocoRED = require("../../../crocodyle/assets/images/crocoRED.png");
const crocoYELLOW = require("../../../crocodyle/assets/images/crocoYELLOW.png");
const crocoAVEUGLE = require("../../../crocodyle/assets/images/crocoAVEUGLE.png");

export function ConnectFourForm() {
    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("submit", e);
        //get the value of the input #blind
        const blind = document.getElementById("blind") as HTMLInputElement;
        const blindToken = document.getElementById(
            "blind-token"
        ) as HTMLInputElement;
        const channelName = document.getElementById(
            "channel-name"
        ) as HTMLInputElement;
        const notUniqueMessage = document.getElementById(
            "not-unique-message"
        ) as HTMLInputElement;
        const enableTeam = document.getElementById(
            "enable-team"
        ) as HTMLInputElement;
        const turnTime = document.getElementById(
            "turn-time"
        ) as HTMLInputElement;

        navigate({
            pathname: "/crocodyle/connect-four/game",
            search:
                "?channel_name=" +
                channelName.value +
                "&not_unique_message=" +
                notUniqueMessage.checked +
                "&enable_team=" +
                enableTeam.checked +
                "&turn_time=" +
                (parseInt(turnTime.value) <= 0 ? 1 : turnTime.value) +
                "&blind=" +
                blind.checked +
                "&blindToken=" +
                blindToken.checked,
        });
    };
    const theme = useTheme();
    const isDark = theme.palette.mode === "dark";
    const textColor = isDark ? "white" : "black";

    return (
        <div>
            <Typography
                variant={"h4"}
                className='crocodyle__title'
                color={textColor}>
                <img src={crocoYELLOW} alt='crocoYELLOW' width={40} />
                Puissance 4
                <img src={crocoRED} alt='crocoRED' width={40} />
            </Typography>
            <form className='crocodyle__form' onSubmit={handleSubmit}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox id='blind' />}
                        label={
                            <Typography
                                variant='body1'
                                className='crocodyle__label'
                                color={textColor}>
                                Cacher le bas de la grille
                                <img
                                    src={crocoAVEUGLE}
                                    alt='crocoAVEUGLE'
                                    width={30}
                                />
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        control={<Checkbox id='blind-token' />}
                        label={
                            <Typography
                                variant='body1'
                                className='crocodyle__label'
                                color={textColor}>
                                Cacher les jetons posés
                                <img
                                    src={crocoAVEUGLE}
                                    alt='crocoAVEUGLE'
                                    width={30}
                                />
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        control={<Checkbox defaultChecked id='enable-team' />}
                        label={
                            <Typography
                                variant='body1'
                                className='crocodyle__label'
                                color={textColor}>
                                Choix des équipes
                                <img
                                    src={crocoYELLOW}
                                    alt='crocoYELLOW'
                                    width={30}
                                />
                                vs
                                <img src={crocoRED} alt='crocoRED' width={30} />
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        control={<Checkbox id='not-unique-message' />}
                        label={
                            <Typography
                                variant='body1'
                                className='crocodyle__label'
                                color={textColor}>
                                Plusieurs votes par viewer
                                <img src={MODS} alt='MODS' width={30} />
                            </Typography>
                        }
                    />
                </FormGroup>
                <TextField
                    id='channel-name'
                    className='crocodyle__input'
                    label='Nom de la chaine twitch'
                    variant='outlined'
                    required
                    defaultValue={"crocodyle_lol"}
                />
                <TextField
                    id='turn-time'
                    className='crocodyle__input'
                    label="Durée d'un tour (en secondes)"
                    variant='outlined'
                    required
                    defaultValue={10}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
                <Button
                    className='crocodyle__input'
                    type='submit'
                    variant='contained'>
                    Commencer
                </Button>
            </form>
        </div>
    );
}
