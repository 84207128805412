import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import { createContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.scss";
import { AppRoute, AppRouteProps, appRoutes } from "./AppRoute";
import Header from "./components/Header";
import { HeaderButtonProps } from "./models/HeaderButton";

function GetActiveTab(buttons: HeaderButtonProps[], pathname: string) {
    const activeTab = buttons.findIndex((button) => {
        if (typeof button.action === "string") {
            return button.action === pathname;
        } else if (typeof button.action === "object") {
            return button.action.find((child) => {
                return child.action === pathname;
            });
        }
        return false;
    });
    return activeTab === -1 ? 0 : activeTab;
}

export const ColorModeContext = createContext({
    toggleColorMode: () => {},
});

function App() {
    const { pathname } = useLocation();
    const [currentRoute, setCurrentRoute] = useState<AppRouteProps>();
    const currentTheme = currentRoute?.theme ?? themeNothing;
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    useEffect(() => {
        const path =
            pathname.length > 1 && pathname.endsWith("/")
                ? pathname.slice(0, -1)
                : pathname;
        const route = appRoutes.find((route) => route.path === path);
        if (route && route.header && route.header.buttons) {
            route.header.activeTab = GetActiveTab(route.header.buttons, path);
        }
        if (route === undefined) {
            setCurrentRoute(appRoutes[0]);
            return;
        }
        setCurrentRoute(route);
    }, [pathname]);

    const [mode, setMode] = useState<"light" | "dark">(
        (localStorage.getItem("theme") as "light" | "dark") ??
            (prefersDarkMode ? "dark" : "light")
    );
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                localStorage.setItem(
                    "theme",
                    mode === "light" ? "dark" : "light"
                );
                setMode((prevMode) =>
                    prevMode === "light" ? "dark" : "light"
                );
            },
        }),
        [mode]
    );

    const theme = useMemo(() => {
        return createTheme({
            ...currentTheme,
            palette: {
                primary: {
                    ...currentTheme.palette.primary,
                },
                mode,
                secondary: {
                    ...currentTheme.palette.secondary,
                },
                background:
                    currentTheme.palette.primary.main === "#00000000"
                        ? {
                              default: "#00000000",
                              paper: "#00000000",
                          }
                        : {},
            },
            shape: {
                borderRadius: 8,
            },
        });
    }, [mode, currentTheme]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <header className='no-select'>
                    <Header {...currentRoute?.header} />
                </header>
                <div
                    className={
                        currentRoute?.header ? "app offset-header" : "app"
                    }
                    style={{
                        backgroundColor: theme.palette.background.default,
                    }}>
                    <AppRoute />
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;

const themeNothing = createTheme({
    palette: {
        primary: {
            contrastText: "#00000000",
            dark: "#00000000",
            light: "#00000000",
            main: "#00000000",
        },
        background: {
            default: "#00000000",
        },
    },
    shape: {
        borderRadius: 8,
    },
});
