import { Link } from "react-router-dom";
import { GamePresentation } from "../../../components/GamePresentation";

export function JustDodge() {
    return (
        <div style={{ backgroundColor: "#000000", flex: 1 }}>
            <GamePresentation
                title={"Just Dodge"}
                trailer='https://www.youtube.com/embed/Q_Mry5yKh9c'
                image={require("../../../assets/icons/undertale.png")}
                textColor={"#FFFFFF"}
                buttonType='outlined'
                description={(color) => (
                    <>
                        <p className='paragraph'>
                            Just Dodge est une nouvelle version de{" "}
                            <Link
                                to='/game/bullet-project'
                                style={{ color: color }}>
                                Bullet Project
                            </Link>{" "}
                            qui est un jeu basé sur Undertale. Celui-ci a été
                            réalisé en 2021 en OCaml et compilé en Javascript.
                        </p>
                        <p className='paragraph'>
                            Le but du jeu est de survivre le plus longtemps
                            possible en esquivant les obstacles.
                        </p>
                        <p className='paragraph'>
                            Pour se déplacer dans le jeu et dans les menus, il
                            faut utiliser ZQSD.
                        </p>
                    </>
                )}
                download={
                    <iframe
                        src={process.env.PUBLIC_URL + "/game-player/just-dodge"}
                        style={{
                            maxWidth: "800px",
                            width: "100%",
                            aspectRatio: "4/3",
                            border: "1px solid #FFFFFF",
                            borderRadius: "8px",
                        }}
                        className='no-select'
                        title='Just Dodge'
                    />
                }
            />
        </div>
    );
}
