import React from "react"
import { TokenType } from "../../../pages/crocodyle/connect-four/game/ConnectFourGame";
const p4Red = require("../../assets/images/p4_red.png");
const p4Yellow = require("../../assets/images/p4_yellow.png");

export default class TokenHead extends React.Component {

    state = {
        type: TokenType.NONE,
        isStarted: false,
        position: 0,
    }

    componentDidMount() {
        this.generateRandomType();
    }

    setType = (type) => {
        this.setState({
            type: type
        })
    }

    getType = () => {
        return this.state.type;
    }

    setPosition = (position) => {
        this.setState({
            position: position
        })
    }

    getPosition = () => {
        return this.state.position;
    }

    reset = () => {
        this.setState({
            type: TokenType.NONE,
            isStarted: false,
            position: 0,
        })
    }

    generateRandomType = () => {
        const newType = Math.floor(Math.random() * 2) + 1;
        this.setType(newType);
        return newType;
    }

    swapType = () => {
        if(this.state.type === TokenType.NONE) return 0;
        const newType = this.state.type === TokenType.YELLOW ? TokenType.RED : TokenType.YELLOW;
        this.setType(newType);
        return newType;
    }

    startGame = () => {
        this.setState({
            isStarted: true,
        });
    }

    render() {
        const {type, isStarted, position} = this.state;

        const token = type === TokenType.YELLOW ? p4Yellow : (type === TokenType.RED ? p4Red : null);

        if(isStarted) {
            return (
                <div
                    className="tile"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "calc((50px * 1.18) * " + position + ")"
                    }}>
                    {
                        token === null ? null :
                            <img src={token} className="token" alt="token"/>
                    }
                </div>
            );
        } else {
            return (
                <div className="tile animation" style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "end"}}>
                    {
                        token === null ? null :
                            <img src={token} className="token" alt="token"/>
                    }
                </div>
            );
        }
    }
};