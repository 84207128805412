import {
    createTheme,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { GamePresentation } from "../../../components/GamePresentation";
import "./Game.scss";

export function BomberStars() {
    return (
        <div>
            <div className='game__background__container'>
                <img
                    src={require("../../../assets/images/bomber_stars_background.webp")}
                    className='bomber-stars__background'
                    alt='Background'
                />
                <span className='bomber-stars__background__color' />
            </div>
            <div className='game__container'>
                <GamePresentation
                    title={"Bomber Stars"}
                    textColor={"#FFFFFF"}
                    buttonType='outlined'
                    image={require("../../../assets/icons/bomber_stars.png")}
                    description={(color) => (
                        <>
                            <p className='paragraph'>
                                Bomber Stars est un jeu basé sur Bomberman
                                (version Sega Saturn) réalisé en 2019.
                            </p>
                            <p className='paragraph'>
                                Ce jeu est jouable à 8 en local (jouable en
                                ligne grâce à{" "}
                                <a
                                    style={{ color: color }}
                                    href='https://parsec.app/'>
                                    Parsec
                                </a>
                                ) et propose pleins de fonctionnalités
                                permettant d'augmenter la durée de vie du jeu,
                                tel qu'un éditeur de carte, où bien la
                                personnalisation des règles.
                            </p>
                            <p className='paragraph'>
                                Le but du jeu est de faire exploser les autres
                                joueurs en utilisant des bombes qui peuvent
                                avoir des effets différent en fonction des bonus
                                du joueur.
                            </p>
                        </>
                    )}
                    download='/download/bomber_stars.exe'
                    table={(color) => (
                        <TableContainer
                            component={Paper}
                            style={{
                                backgroundColor: color,
                                width: "calc(100vw - 80px)",
                            }}>
                            <Table aria-label='simple table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                color: "#FFFFFF",
                                            }}>
                                            Nom de l'action
                                        </TableCell>
                                        <TableCell
                                            align='right'
                                            style={{
                                                color: "#FFFFFF",
                                            }}>
                                            Manette
                                        </TableCell>
                                        <TableCell
                                            align='right'
                                            style={{
                                                color: "#FFFFFF",
                                            }}>
                                            Clavier 1
                                        </TableCell>
                                        <TableCell
                                            align='right'
                                            style={{
                                                color: "#FFFFFF",
                                            }}>
                                            Clavier 2
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {inputs.map((input) => (
                                        <TableRow
                                            key={input.name}
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}>
                                            <TableCell
                                                component='th'
                                                scope='row'
                                                style={{
                                                    color: "#FFFFFF",
                                                }}>
                                                {input.name}
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                style={{
                                                    color: "#FFFFFF",
                                                }}>
                                                {input.controller}
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                style={{
                                                    color: "#FFFFFF",
                                                }}>
                                                {input.keyboard1}
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                style={{
                                                    color: "#FFFFFF",
                                                }}>
                                                {input.keyboard2}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                />
            </div>
        </div>
    );
}

export const themeBomberStars = createTheme({
    palette: {
        mode: "dark",
        primary: {
            contrastText: "#FFFFFF",
            main: "#002446",
        },
        secondary: {
            contrastText: "#FFFFFF",
            main: "#ffda00",
        },
    },
    shape: {
        borderRadius: 8,
    },
});

type BomberStarsInput = {
    name: string;
    keyboard1: string;
    keyboard2: string;
    controller: string;
};
const inputs: BomberStarsInput[] = [
    {
        name: "Déplacement",
        keyboard1: "Flêches directionnelles",
        keyboard2: "ZQSD",
        controller: "Stick gauche",
    },
    {
        name: "Poser une ligne de bombe",
        keyboard1: "Ctrl Droit",
        keyboard2: "Shift Droit",
        controller: "X",
    },
    {
        name: "Activer la détonation des bombes",
        keyboard1: "Numpad0",
        keyboard2: "F",
        controller: "B",
    },
    {
        name: "Posé une bombe",
        keyboard1: "A",
        keyboard2: "Espace",
        controller: "A",
    },
    {
        name: "Pause",
        keyboard1: "Echap",
        keyboard2: "Echap",
        controller: "Start",
    },
];
