import React from "react";
import { TokenType } from "../../../pages/crocodyle/connect-four/game/ConnectFourGame";
const crocoRED = require("../../assets/images/crocoRED.png");
const crocoYELLOW = require("../../assets/images/crocoYELLOW.png");

interface IProps {
    enableTeam: boolean;
}

export default class ScoreBoard extends React.Component<IProps> {
    state = {
        type: TokenType.NONE,
        isStarted: false,
        isFinish: false,
        yellow: 0,
        red: 0,
    };

    setType = (type: number) => {
        this.setState({
            type: type,
        });
    };

    getType = () => {
        return this.state.type;
    };

    start = () => {
        this.setState({
            isStarted: true,
        });
    };

    setValues = (red: number, yellow: number) => {
        this.setState({
            red: red,
            yellow: yellow,
        });
    };

    setRed = (red: number) => {
        this.setState({
            red: red,
        });
    };

    setYellow = (yellow: number) => {
        this.setState({
            yellow: yellow,
        });
    };

    finish = () => {
        this.setState({
            isFinish: true,
        });
    };

    reset = () => {
        this.setState({
            type: TokenType.NONE,
            isStarted: false,
            isFinish: false,
        });
    };

    render() {
        const { type, isStarted, red, yellow, isFinish } = this.state;

        const { enableTeam } = this.props;

        const token =
            type === TokenType.YELLOW
                ? crocoYELLOW
                : type === TokenType.RED
                ? crocoRED
                : null;

        if (!isStarted) {
            if (!enableTeam) return <div className={"side"} />;
            return (
                <div className={"side column"}>
                    <div className={"row"}>
                        <div className={"column"} style={{ marginRight: 10 }}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className={"text"}>{yellow}</a>
                            <img
                                src={crocoYELLOW}
                                className='team-icon'
                                alt='team-icon'
                            />
                        </div>
                        <div className={"column"} style={{ marginLeft: 10 }}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className={"text"}>{red}</a>
                            <img
                                src={crocoRED}
                                className='team-icon'
                                alt='team-icon'
                            />
                        </div>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className={"text"}>
                        Rejoignez votre équipe en mettant l'emote{" "}
                        <img
                            src={crocoYELLOW}
                            className='token-little'
                            alt='token-little'
                        />{" "}
                        ou{" "}
                        <img
                            src={crocoRED}
                            className='token-little'
                            alt='token-little'
                        />{" "}
                        dans le chat
                    </a>
                </div>
            );
        }

        if (isFinish) {
            return (
                <div className={"side"}>
                    {token === null ? (
                        <div className={"column center-vertical"}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className={"text"}>Aucun gagnant</a>
                        </div>
                    ) : (
                        <div className={"column center-vertical"}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className={"text"}>Victoire pour les</a>
                            <img
                                src={token}
                                className='token-big'
                                alt='token-big'
                            />
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <div className={"side column"}>
                    {!enableTeam ? null : (
                        <div className={"row"}>
                            <div
                                className={"column"}
                                style={{ marginRight: 10 }}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className={"text"}>{yellow}</a>
                                <img
                                    src={crocoYELLOW}
                                    className='team-icon'
                                    alt='team-icon'
                                />
                            </div>
                            <div
                                className={"column"}
                                style={{ marginLeft: 10 }}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className={"text"}>{red}</a>
                                <img
                                    src={crocoRED}
                                    className='team-icon'
                                    alt='team-icon'
                                />
                            </div>
                        </div>
                    )}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <span className={"text"}>
                        Votez dans le chat en envoyant le numéro de la colonne
                        (entre 1 et 7)
                    </span>
                </div>
            );
        }
    }
}
