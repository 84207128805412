import MediaCard, { MediaCardProps } from "../../components/MediaCard";

export function SwordsoulerContact() {
    return (
        <div className='row center-horizontal start-vertical'>
            {cardsSwordsoulerContact.map((card) => (
                <MediaCard key={card.title} {...card} />
            ))}
        </div>
    );
}

export const cardsSwordsoulerContact: MediaCardProps[] = [
    {
        title: "Twitch",
        description: "",
        img: require("../../assets/icons/twitch.png"),
        links: [
            {
                name: "Accéder à ma chaine Twitch",
                link: "https://www.twitch.tv/swordsouler",
            },
        ],
    },
    {
        title: "Twitter",
        description: "",
        img: "https://cdn-icons-png.flaticon.com/512/733/733579.png",
        links: [
            {
                name: "Accéder à mon compte Twitter",
                link: "https://twitter.com/swordsouler",
            },
        ],
    },
    {
        title: "Github",
        description: "",
        img: "https://cdn-icons-png.flaticon.com/512/733/733553.png",
        links: [
            {
                name: "Accéder à mon compte Github",
                link: "https://github.com/Swordsouler",
            },
        ],
    },
    {
        title: "Discord",
        description: "",
        img: "https://www.freepnglogos.com/uploads/discord-logo-png/concours-discord-cartes-voeux-fortnite-france-6.png",
        links: [
            {
                name: "Compte",
                link: "https://discordapp.com/users/229712641336147968",
            },
            {
                name: "Serveur",
                link: "https://discord.com/invite/z4X36K4A6K",
            },
        ],
    },
];
